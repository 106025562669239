import { Application } from "stimulus"
import { registerControllers } from "stimulus-vite-helpers"
import env from "../env"

const application = Application.start()
const controllers = import.meta.glob("./**/*_controller.js", { eager: true })
registerControllers(application, controllers)

if (env === "test") {
  // propagate errors that happen inside Stimulus controllers
  application.handleError = (error, message, detail) => {
    throw error
  }
}
