import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.$modal = $(this.element)
  }

  disconnect() {
    this.hide()
  }

  hide(event) {
    event?.preventDefault()
    this.$modal.modal("hide")
  }
}
