import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.timeout = setTimeout(this.closeModal.bind(this), 1)
  }

  disconnect() {
    clearTimeout(this.timeout)
    delete this.timeout
  }

  closeModal() {
    const event = document.createEvent("MouseEvents")
    event.initEvent("click", true, false)
    this.element.dispatchEvent(event)

    this.timeout = setTimeout(this.terminate.bind(this), 1)
  }

  terminate() {
    this.element.remove()
  }
}
