/*
 * Classes:
 *  - copy-remove-class: Class(es) removed from the `classApplicant` before appllication of other classes
 *  - copy-success-class: Class(es) added to the `classApplicant` in case of success
 *  - copy-failure-class: Class(es) added to the `classApplicant` in case of failure
 *
 * Values:
 *  - success-message-value: Message to which the `classApplicant` inner text will be set in case of success
 *  - failure-message-value: Message to which the `classApplicant` inner text will be set in case of failure
 *  - timeout-value: Number of milliseconds until the `classApplicant` returns to it's original state (if unset the applicant will never return to it's original state)
 *  - message-value: Text to copy (only applicable if no target is present)
 *
 * Targets:
 *  - target: Element who's contents will be copied
 *  - classApplicant: Element to which all classes and messages will be applied in case of success or failure
 *
 * Actions:
 *  - copy: Copies the target element's contents or message from the data attribute to the usern's clipboard
 *
 * Examples:
 *  Copying from a visible element:
 *  ```
 *  <div data-controller='copy-to-clipboard'
 *       data-copy-to-clipboard-copy-success-class="btn-success-disabled"
 *       data-copy-to-clipboard-copy-failure-class="btn-warning"
 *       data-copy-to-clipboard-remove-class="btn-default"
 *       data-copy-to-clipboard-timeout-value="3000"
 *       data-copy-to-clipboard-success-message-value="Copied!"
 *       data-copy-to-clipboard-failure-message-value="Uhh, ohh, the copy failed :(">
 *    <p data-copy-to-clipboard-target="target">
 *      Some text you would like to copy :)
 *    </p>
 *    <button class="btn-default" data-copy-to-clipboard-target="classApplicant" data-action="click->copy-to-clipboard#copy">
 *      Copy
 *    </button>
 *  </div>
 *  ```
 *
 *  Copy text from data attribute:
 *  ```
 *  <div data-controller='copy-to-clipboard'
 *       data-copy-to-clipboard-copy-success-class="btn-success-disabled"
 *       data-copy-to-clipboard-copy-failure-class="btn-warning"
 *       data-copy-to-clipboard-remove-class="btn-default"
 *       data-copy-to-clipboard-timeout-value="3000"
 *       data-copy-to-clipboard-success-message-value="Copied!"
 *       data-copy-to-clipboard-failure-message-value="Uhh, ohh, the copy failed :("
 *       data-copy-to-clipboard-message-value="Some text you would like to copy :)">
 *    <button class="btn-default" data-copy-to-clipboard-target="classApplicant" data-action="click->copy-to-clipboard#copy">
 *      Copy
 *    </button>
 *  </div>
 *  ```
 */
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "target", "classApplicant" ]
  static classes = [ "copySuccess", "copyFailure", "remove" ]
  static values = {
    timeout: Number,
    successMessage: String,
    failureMessage: String,
    message: String,
    disabled: Boolean
  }

  disconnect() {
    if (this.classApplicationTimeout) clearTimeout(this.classApplicationTimeout)
  }

  copy(event) {
    if (this.hasDisabledValue && this.disabledValue) return
    if (!this.hasTargetTarget && !this.hasMessageValue) return

    if (!this.hasTargetTarget && this.hasMessageValue) {
      this.createTempTarget()
    }

    event.preventDefault()
    event.stopPropagation()

    try {
      if (navigator.clipboard) {
        const text = this.targetTarget.value !== "" ? this.targetTarget.value : this.targetTarget.innerText

        navigator.clipboard.writeText(text)
          .then(() => this.copySuccessHandler())
          .catch((err) => this.copyFailureHandler(err))
      }
      else if (document.execCommand) {
        const selection = document.getSelection()
        const range = document.createRange()
        range.selectNode(this.targetTarget)
        selection.removeAllRanges()
        selection.addRange(range)
        document.execCommand("copy") ? this.copySuccessHandler() : this.copyFailureHandler("Unknown reason")
      }
      else {
        this.copyFailureHandler("Copy unsupported")
      }
    } catch(err) {
      this.copyFailureHandler(err)
    }

    window.getSelection().removeAllRanges()


    this.destroyTempTarget()
  }

  createTempTarget() {
    this.tempTarget = document.createElement("div")
    this.tempTarget.innerText = this.messageValue
    this.tempTarget.value = this.messageValue
    this.tempTarget.dataset.copyToClipboardTarget = "target"
    this.tempTarget.style.position = "absolute"
    this.tempTarget.style.width = "0"
    this.tempTarget.style.height = "0"
    this.tempTarget.style.top = "0"
    this.tempTarget.style.left = "0"
    this.element.appendChild(this.tempTarget)
  }

  destroyTempTarget() {
    if (!this.tempTarget) return

    this.tempTarget.remove()
    this.tempTarget = null
  }

  copySuccessHandler() {
    if (this.hasSuccessMessageValue) {
      this.applyMessageWithTimeout(this.successMessageValue)
    }

    if (this.hasCopySuccessClass) {
      this.applyClassWithTimeout(this.copySuccessClass)
    }
  }

  copyFailureHandler(error) {
    console.log(`[ERROR] Failed to copy contents due to: ${error}`)

    if (this.hasFailureMessageValue) {
      this.applyMessageWithTimeout(this.failureMessageValue)
    }

    if (this.hasCopyFailureClass) {
      this.applyClassWithTimeout(this.copyFailureClass)
    }
  }

  applyClassWithTimeout(klass) {
    if (!this.hasClassApplicantTarget) return

    this.classApplicantTarget.classList.add(klass)

    if (this.hasRemoveClass) {
      this.classApplicantTarget.classList.remove(this.removeClass)
    }

    if (!this.hasTimeoutValue) return

    if (this.classApplicationTimeout) clearTimeout(this.classApplicationTimeout)

    this.classApplicationTimeout =
      setTimeout(
        function() {
          if (!this.hasClassApplicantTarget) return

          this.classApplicantTarget.classList.remove(klass)

          if (this.hasRemoveClass) {
            this.classApplicantTarget.classList.add(this.removeClass)
          }
        }.bind(this),
        this.timeoutValue
      )
  }

  applyMessageWithTimeout(message) {
    if (!this.hasClassApplicantTarget) return
    if (!message) return
    if (this.originalClassApplicant) return

    this.originalClassApplicant = this.classApplicantTarget.cloneNode(true)
    this.classApplicantTarget.innerHTML = message

    if (this.messageApplicationTimeout) clearTimeout(this.messageApplicationTimeout)

    this.messageApplicationTimeout =
      setTimeout(
        function() {
          if (!this.hasClassApplicantTarget) return

          if (this.originalClassApplicant) {
            this.classApplicantTarget.parentNode.replaceChild(
              this.originalClassApplicant,
              this.classApplicantTarget
            )
            this.originalClassApplicant = null
          }
        }.bind(this),
        this.timeoutValue
      )
  }
}
